<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <c-card class="cardClassDetailForm" title="상세">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <c-text
                :editable="false"
                :readonly="true"
                label="작성정보"
                name="reg"
                v-model="reg">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <c-radio
                :editable="false"
                codeGroupCd="SAI_INTERNAL_ACTION_TYPE_CD"
                label="심사구분"
                name="col1"
                v-model="action.col1">
              </c-radio>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <c-plant
                :editable="false"
                name="plantCd"
                v-model="action.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <c-textarea
                :editable="false"
                :rows="10"
                label="심사 목적 및 범위"
                name="col3"
                v-model="action.col3">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <c-textarea
                :editable="false"
                :rows="10"
                label="심사 준비 요청사항"
                name="col2"
                v-model="action.col2">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="심사팀 목록"
          :columns="gridTeam.columns"
          :data="action.auditTeams"
          :gridHeight="gridTeam.height"
          :editable="false"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-info-side',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridTeam: {
        columns: [
          {
            name: 'roleCd',
            field: 'roleCd',
            label: '역할',
            align: 'center',
            style: 'width:30%',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '심사팀장', },
              { code: '2', codeName: '심사자', },
            ],
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '심사팀',
            align: 'center',
            style: 'width:40%',
            sortable: true,
          },
        ],
        height: '390px',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    reg() {
      return this.action.regUserName + ' / ' + this.action.regDtStr
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
  }
};
</script>